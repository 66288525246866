import market_v1 from '../assets/png/project/market-v1.png'
import market_v2 from '../assets/png/project/market-v2.png'
import seller from '../assets/png/project/seller.png'
import cms from '../assets/png/project/cms.png'

// import one from '../assets/svg/projects/one.svg'
// import two from '../assets/svg/projects/two.svg'
// import three from '../assets/svg/projects/three.svg'
// import four from '../assets/svg/projects/four.svg'
// import five from '../assets/svg/projects/five.svg'
// import six from '../assets/svg/projects/six.svg'
// import seven from '../assets/svg/projects/seven.svg'
// import eight from '../assets/svg/projects/eight.svg'

const descMarket = `Agree Market is a web-based platform to facilitate the transaction process
of agricultural commodities with a B2B scheme between off-takers and MSMEs, HORECA (Hotel,
Restaurant & Café), Enterprise, Start-Ups and Supermarkets`;


export const projectsData = [
  {
    id: 1,
    projectName: 'Agree Market',
    projectDesc: descMarket,
    tags: ['Next js', 'CSS'],
    code: '',
    demo: 'https://market.agreeculture.id',
    image: market_v1
  },
  {
    id: 2,
    projectName: 'New Agree Market',
    projectDesc: descMarket,
    tags: ['Next js', 'CSS', 'Legion UI'],
    code: '',
    demo: 'https://stage-new-market.agreeculture.id',
    image: market_v2
  },
  {
    id: 3,
    projectName: 'Dashboard Seller',
    projectDesc: `This dashboard offers various features and essential information
    that enables sellers to manage products, track sales, monitor inventory,
    and interact with customers effortlessly`,
    tags: ['React js', 'CSS'],
    code: '',
    demo: 'https://dev-seller.agreeculture.id/login',
    image: seller
  },
  {
    id: 4,
    projectName: 'Agree Market CMS',
    projectDesc: `Agree Market CMS is an intuitive Content Management System,
    enabling efficient management and easy updating of product content on the Agree Market platform`,
    tags: ['React js', 'CSS'],
    code: '',
    demo: 'https://market-cms-web-dev.vsan-apps.playcourt.id/login',
    image: cms
  },
  // {
  //     id: 5,
  //     projectName: 'Agree Market CMS',
  //     projectDesc: 'A Simple E-commerce application',
  //     tags: ['React Native', 'Firebase'],
  //     code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
  //     demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
  //     image: cms
  // },
  // {
  //     id: 6,
  //     projectName: 'Uber Lite',
  //     projectDesc: 'Uber clone',
  //     tags: ['Flutter'],
  //     code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
  //     demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
  //     image: six
  // },
  // {
  //     id: 7,
  //     projectName: 'Stock Market App',
  //     projectDesc: 'A simple stock market API app',
  //     tags: ['React', 'Redux', 'Bootstrap'],
  //     code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
  //     demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
  //     image: seven
  // },
  // {
  //     id: 8,
  //     projectName: 'Car Pooling System',
  //     projectDesc: 'The carpooling system merges multiple people in a car which leads to meet new people, reduces pollution',
  //     tags: ['Flutter', 'React'],
  //     code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
  //     demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
  //     image: eight
  // },
]


// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/