export const experienceData = [
    {
        id: 1,
        company: 'Telkom Indonesia',
        jobtitle: 'Frontend Web Developer',
        startYear: '2020',
        endYear: 'Present'
    },
    {
        id: 2,
        company: 'PT. Dreta Teknologi Solusindo',
        jobtitle: 'Android Developer',
        startYear: '2018',
        endYear: '2019'
    },
    {
        id: 3,
        company: 'PT. HER Buana Pratama',
        jobtitle: 'Engineering',
        startYear: '2009',
        endYear: '2017'
    },
];
