import dilo from '../assets/jpg/certificate/sertifikat_developer_camp.jpg';
import baba from '../assets/jpg/certificate/sertifikat_front-end.jpg';

export const achievementData = {
  bio: `Through various courses and training sessions I have attended,
    I have achieved significant milestones in developing technical skills
    and knowledge in my field. My participation in these programs has
    positively contributed to my ability to solve problems and consistently
    add value in my work scope.`,
  achievements: [
    {
      id: 1,
      title: 'Certificate of Training',
      details: 'DILo training programme with supported by TELKOM CDC',
      date: 'Dec 19, 2019',
      field: 'Automation',
      image: dilo
    },
    {
      id: 2,
      title: 'Babastudio Certification',
      details: 'For succesfully completing training of Front-end & Back-end Developer',
      date: 'Nov 25, 2019',
      field: 'Automation',
      image: baba
    },
    // {
    //   id: 3,
    //   title: 'Professional Data Engineer Certification',
    //   details: 'Professional Data Engineers enable data-driven decision making by collecting, transforming, and publishing data.',
    //   date: 'Aug 20, 2019',
    //   field: 'Automation',
    //   image: 'https://images.unsplash.com/photo-1489389944381-3471b5b30f04?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80'
    // }
  ]
}


// Do not remove any fields.
// Leave it blank instead as shown below.

/*

export const achievementData = {
    bio : "",
    achievements : []
}

*/