export const aboutData = {
    title: "Who I am",
    description1: `I am a Web Developer based in Wonosobo, Central Java,
    with a full dedication to creating innovative and high-quality web solutions.
    Currently, I serve as a Web Developer at one of the leading state-owned enterprises (BUMN) in Indonesia.`,
    description2: `My experience in the industry has enriched my technical skills
    and provided me with a deep understanding of the latest developments in web development.`,
    description3: `Outside of work, I cherish moments with my family during holidays.
    Striking a balance between work and personal life is crucial to me.
    Additionally, I have a profound interest in technology and enjoy exploring new things in this ever-evolving world.`,
    description4: `With my dedication to web development and a passion for continuous learning,
    I always strive to make meaningful contributions to every project I undertake.
    I believe that web technology has immense potential to shape the future,
    and I am committed to being a part of that positive change.`,
    image: 2
}