import resume from '../assets/pdf/resume_wanto.pdf'
import imageProfile from '../assets/jpg/wanto.jpg'

export const headerData = {
    name: 'Wanto',
    title: "Web Developer",
    desciption1: `Hello! I'm Wanto, a web developer with a strong passion for creating stunning digital experiences.
    I possess expertise in designing and developing innovative web solutions that are not
    only functional but also aesthetically pleasing and user-friendly.`,
    desciption2: `With a robust background in web technology,
    I have successfully crafted creative projects that reflect the vision and goals of clients.
    My skills encompass the use of cutting-edge technology and best development practices
    to ensure that every project I handle delivers significant value.`,
    image: imageProfile,
    resumePdf: resume
}
